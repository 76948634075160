import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { Box } from 'rebass';
import slugify from '@sindresorhus/slugify';

import Layout from '../components/Layout';
import Img from 'gatsby-image';
import { CTA } from '../components/ui/CTA';
import { ArticleContainer } from '../components/ui/ArticleContainer';
import Button from '../components/ui/Button';

function DataCell({
  bold = false,
  children,
}: {
  bold?: boolean;
  children: React.ReactNode;
}) {
  return <td>{bold ? <b>{children}</b> : children}</td>;
}

function getRandomizedTerm({
  terms,
  index,
}: {
  terms: string[];
  index: number;
}) {
  return terms[index % terms.length];
}

function getRandomizedTitle({
  index,
  cityName,
}: {
  index: number;
  cityName: string;
}) {
  return `${getRandomizedTerm({
    terms: ['Entreprise', 'Société', 'Agence'],
    index,
  })} de sécurité ${getRandomizedTerm({
    terms: ['privée ', ''],
    index,
  })}à ${cityName}`;
}

function TemplateContent({ title, helmet, pageContext, data }) {
  const { agentSecuriteImage, societeSecuriteImage } = data;
  const firstParagraphVariants = [
    <p>
      Vous êtes à la recherche d’une <strong>société de gardiennage</strong>{' '}
      afin de faire appel à des agents de sécurité à {pageContext.city.nom},
      dans le {pageContext.department.code} ? Demandez vos devis gratuits. Nous
      sommes en mesure de répondre à vos besoins très rapidement !
    </p>,
    <p>
      Vous avez besoin des services d’une <strong>société de sécurité</strong> à{' '}
      {pageContext.city.nom}, dans le département {pageContext.department.code}{' '}
      ? Envoyez nous votre demande et recevez gratuitement et très rapidement{' '}
      vos devis gratuits.
    </p>,
    <p>
      Vous recherchez une <strong>entreprise de sécurité</strong> en mesure de
      vous fournir les service d’<strong>agents de sécurité</strong> à{' '}
      <strong>{pageContext.city.nom}</strong> ? Demandez-nous votre devis{' '}
      gratuit. Nos agences interviennent dans tout le département{' '}
      {pageContext.department.code}.
    </p>,
  ];
  const index = pageContext.cityRandomIndex % firstParagraphVariants.length;

  const firstParagraph = firstParagraphVariants[index];
  const slug = `${slugify(pageContext.department.region.nom)}/${slugify(
    pageContext.department.nom
  )}/${slugify(pageContext.city.nom)}`;

  return (
    <ArticleContainer>
      <article className="mx-auto prose prose-lg text-gray-600 prose-indigo">
        {helmet || ''}
        <h1>
          <span className="block mb-4 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
            {`Agent de sécurité à ${pageContext.city.nom}`}
          </span>
        </h1>
        <Box mt={50}>{firstParagraph}</Box>
        <CTA
          href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
        >
          Recevez vos devis gratuits en quelques minutes
        </CTA>
        <Box my={4} />
        <Img
          {...agentSecuriteImage.childImageSharp}
          alt={`Agent de sécurité ${pageContext.city.nom}`}
        />
        <h2>
          Sécurisez vos événements et vos locaux à {pageContext.city.nom} avec
          des agents de sécurité
        </h2>
        <p>
          En confiant la sécurité à une entreprise de surveillance, gagnez en
          sérénité et rassurez vos employés, vos visiteurs, et vos invités, afin
          de vous concentrer sur votre cœur de métier ou de profiter l’esprit
          tranquille de l’événement que vous organisez. Nous intervenons à{' '}
          {pageContext.city.nom} et dans tout le département du{' '}
          {pageContext.department.code} ({pageContext.department.nom}). Nous
          pouvons vous aider à sécuriser les situations, événements, et lieux
          suivants, entre autres :
        </p>
        <ul>
          <li>Soirées d’entreprise</li>
          <li>Conférences</li>
          <li>Expositions</li>
          <li>Soirées privées, anniversaires</li>
          <li>Inauguration d’entreprise</li>
          <li>Sites industriels</li>
          <li>Immeubles d’entreprises</li>
          <li>Entrepôts</li>
          <li>Magasins, commerces, supermarchés</li>
          <li>Manifestations sportives</li>
          <li>Mariages</li>
          <li>Concerts</li>
        </ul>
        <Box my={4}>
          <CTA
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            Besoin d’agents de sécurité ? Demandez votre devis gratuit
          </CTA>
        </Box>
        <Img
          {...societeSecuriteImage.childImageSharp}
          alt={`Société de sécurité ${pageContext.city.nom}`}
        />
        <h2>
          Demandez votre devis pour des agents de sécurité à{' '}
          {pageContext.city.nom}
        </h2>
        <p>
          En effectuant votre demande sur Lead Advisor, notre plateforme
          consacrée à la sécurité privée, vous gagnerez du temps et serez en
          mesure de comparer plusieurs propositions de la part d’entreprises de
          sécurité privées. Le dépôt de votre demande de devis se fait via un
          bref questionnaire à choix multiple permettant de spécifier vos
          besoins avec précision en deux minutes. Vous recevrez jusqu’à 6
          réponses et vous pourrez consulter le profil et les certifications des
          entreprises de surveillance qui vous feront une proposition.
        </p>
        <Box my={4}>
          <CTA
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            Déposez votre demande pour des agents de sécurité
          </CTA>
        </Box>
        <p>
          Lead Advisor vous permet de bien choisir votre société de sécurité en
          comparant plusieurs devis. Le secteur de la sécurité est assez
          singulier avec un nombre non négligeable d’irrégularités. Votre choix
          doit donc se faire avec soin, sans forcément se porter sur la
          structure proposant les tarifs les plus attractifs, mais sur un
          établissement fiable et sérieux. Nous vous recommandons la lecture de
          notre article donnant des{' '}
          <Link to="/bien-choisir-sa-societe-de-gardiennage">
            conseils pour bien choisir sa société de sécurité.
          </Link>
        </p>
        <p>
          Pour vous donner une idée des tarifs horaires dans le secteur de la
          surveillance humaine, nous avons réalisé{' '}
          <Link to="/demander-un-devis-pour-des-agents-de-securite#3-les-differents-types-dagent-de-securite-et-les-tarifs-associes">
            un récapitulatif des prix par type d’agent de sécurité dans notre
            article sur les demandes de devis pour des agents de sécurité.
          </Link>
        </p>
        <h2>
          Différents types d’agents de sécurité pour répondre à toutes les
          situations
        </h2>
        <p>
          En fonction des situations et de la configuration de lieux que vous
          souhaitez protéger à {pageContext.city.nom}, le dispositif de sécurité
          le plus adapté vous sera préconisé. Le nombre et le type d’agent
          variera en effet en fonction de la mission. Voici les différents types
          d’agent de sécurité :
        </p>
        <ul>
          <li>
            Agent de sécurité (ADS) qualifié ou confirmé : l’agent de sécurité
            simple est en mesure de remplir diverses missions : sécurisation
            d’un événement de l’intérieur ou de l’extérieur, contrôle des accès,
            surveillance de locaux d’entreprise, d’un entrepôt, ou d’une maison,
            etc.
          </li>
          <li>
            Agent cynophile : l’agent cynophile est accompagné d’un chien
            spécialement dressé, et peut effectuer des rondes, du filtrage, et
            de la surveillance de lieux exposés tels que les commerces.
          </li>
          <li>
            Rondier intervenant : cet agent effectue des rondes en véhicules
            afin de surveiller un ou plusieurs sites.
          </li>
          <li>
            Agent de sécurité événementiel : il assure la sécurité des
            manifestations auxquelles participent de nombreux visiteurs.
          </li>
          <li>
            Inspecteur de magasin : il doit prévenir les vols en surveillant les
            rayons et en détectant les comportements suspects.
          </li>
          <li>
            Agent d’accueil : il réceptionne les visiteurs et s’occupe de
            contrôler leur identité s’il travaille sur un site sensible.
          </li>
          <li>
            Agent incendie (SSIAP) : obligatoire dans les établissements
            recevant du public, il se charge de la prévention des incendies.
          </li>
        </ul>
        <p>
          Pour plus d’informations à ce sujet, consultez notre{' '}
          <Link to="/agent-de-securite-la-fiche-metier">
            fiche métier sur les agents de sécurité.
          </Link>
        </p>
        <Box my={4}>
          <CTA
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            Besoin d’agents de sécurité ? Demandez votre devis gratuit
          </CTA>
        </Box>
        <h2>Informations sur la sécurité à {pageContext.city.nom}</h2>
        <h3>Les sociétés de sécurité privée à {pageContext.city.nom}</h3>
        <p>
          Il existe {pageContext.city.companyCount} sociétés de sécurité privée
          à {pageContext.city.nom}, inscrites au registre du commerce sous le
          code APE 8010Z (Activités de sécurité privée).
        </p>
        <h3>
          Informations concernant le niveau d’insécurité à{' '}
          {pageContext.city.nom}
        </h3>
        <p>
          À titre informatif, nous vous livrons ici quelques chiffres concernant
          l’insécurité à {pageContext.city.nom}.
        </p>
        <h4>
          Violences aux personnes recensées en 2014 en nombre de cas pour 1000
          habitants
        </h4>
        <table>
          <tr>
            <DataCell bold>{pageContext.city.nom}</DataCell>
            <DataCell bold>{pageContext.city.crime.violence}</DataCell>
          </tr>
          <tr>
            <DataCell>
              {pageContext.city.code === '75056'
                ? pageContext.city.region.nom
                : pageContext.city.departement.nom}
            </DataCell>
            <DataCell>{pageContext.department.crime.violence}</DataCell>
          </tr>
          <tr>
            <DataCell>France</DataCell>
            <DataCell>8,65</DataCell>
          </tr>
        </table>
        <h4>
          Vols et dégradations recensés en 2014 en nombre de cas pour 1000
          habitants
        </h4>
        <table>
          <tr>
            <DataCell bold>{pageContext.city.nom}</DataCell>
            <DataCell bold>{pageContext.city.crime.theft}</DataCell>
          </tr>
          <tr>
            <DataCell>
              {pageContext.city.code === '75056'
                ? pageContext.city.region.nom
                : pageContext.city.departement.nom}
            </DataCell>
            <DataCell>{pageContext.department.crime.theft}</DataCell>
          </tr>
          <tr>
            <DataCell>France</DataCell>
            <DataCell>30,56</DataCell>
          </tr>
        </table>
        <i>
          Source :{' '}
          <a
            href={`http://www.linternaute.com/actualite/delinquance/${slugify(
              pageContext.city.nom
            )}/ville-${pageContext.city.code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`http://www.linternaute.com/actualite/delinquance/${slugify(
              pageContext.city.nom
            )}/ville-${pageContext.city.code}`}
          </a>
        </i>
        <Box my={4} />
      </article>
    </ArticleContainer>
  );
}

const BigCityTemplate = ({ pageContext, data, ...props }) => {
  const { site } = data;
  const title = getRandomizedTitle({
    index: pageContext.cityRandomIndex,
    cityName: pageContext.city.nom,
  });
  const descriptionVariants = [
    `Recevez gratuitement des devis pour des agents de sécurité à ${pageContext.city.nom} en déposant votre demande en deux minutes. Sécurisez votre entreprise ou vos événements à ${pageContext.city.nom} !`,
    `Demandez vos devis gratuits pour des prestations de sécurité à ${pageContext.city.nom} sur Lead Advisor Security. Nous répondrons promptement à vos demandes grâce à notre réseau de plus de 300 agences de sécurité.`,
    `Vous avez besoin d’agents de sécurité pour des locaux ou un évènement à ${pageContext.city.nom} ? Recevez vos devis gratuits sur Lead Advisor Security. Nous mettrons notre réactivité et notre professionalisme à votre service.`,
  ];
  const index = pageContext.cityRandomIndex % descriptionVariants.length;
  const description = descriptionVariants[index];

  const slug = `${slugify(pageContext.department.region.nom)}/${slugify(
    pageContext.department.nom
  )}/${slugify(pageContext.city.nom)}`;

  return (
    <Layout
      sidebarContent={null}
      rightsidebarContent={
        <div className="flex justify-center py-2 mt-12 shadow-md xl:shadow-none bg-indigo-50 xl:bg-transparent xl:mr-8 xl:p-0">
          <a
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            <Button variant="secondary" className="xl:animate-pump">
              Recevez vos devis gratuits en quelques minutes
            </Button>
          </a>
        </div>
      }
    >
      <TemplateContent
        title={title}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${title} - Lead Advisor`}</title>
            <link
              rel="canonical"
              href={`${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slug}`}
            />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta
              property="og:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta name="author" content="David Khoy de Lead Advisor" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@lead_advisor" />
            <meta name="twitter:title" content={title} />
            <meta
              name="twitter:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="/img/og-image.jpg" />

            <script type="application/ld+json">
              {`
{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite",
    "name": "Agent de sécurité"
  },{
    "@type": "ListItem",
    "position": 2,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}",
    "name": "${pageContext.department.region.nom}"
  },{
    "@type": "ListItem",
    "position": 3,
     "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}/${slugify(pageContext.department.nom)}",
    "name": "${pageContext.department.nom}"
  },{
    "@type": "ListItem",
    "position": 4,
     "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.city.region.nom
              )}/${slugify(pageContext.department.nom)}/${slugify(
                pageContext.city.nom
              )}",
    "name": "${pageContext.city.nom}"
  }]
}
`}
            </script>
          </Helmet>
        }
        pageContext={pageContext}
        data={data}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query bigCitySiteMetadata {
    site {
      siteMetadata {
        siteUrl
      }
    }
    agentSecuriteImage: file(
      relativePath: { eq: "agent-de-securite-devis.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    societeSecuriteImage: file(
      relativePath: { eq: "agent-securite-privee.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default BigCityTemplate;
